<template>
  <div class="p-1">
    <div class="flex justify-between mb-2 items-center flex-wrap">
      <h3 class="text-2xl">{{ lang.activities.activities }}</h3>
      <a-button :to="{ name: 'create_activity' }" v-if="user.isAdmin" class="w-full md:w-auto">
        {{ lang.activities.create_activity }}
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @delayed-filter:start_date="search({ ...query, start_date: $event })"
      @delayed-filter:end_date="search({ ...query, end_date: $event })"
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'edit_activity', params: { id: item.id } }">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteActivity(item.id)">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    query: {
      limit: 10,
      search: '',
      start_date: '',
      end_date: '',
      order_by: 'start_date,asc'
    }
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.activities.pagination,
      loading: state => state.activities.loading
    }),
    ...mapGetters(['lang']),
    columns() {
      return [
        { title: this.lang.activities.table.title, key: 'title', display: true },
        { title: this.lang.activities.table.description, key: 'description', display: true },
        { title: this.lang.activities.table.observation, key: 'observation', display: true },
        { title: this.lang.activities.table.start_date, key: 'start_date', display: true, filter: true, preventLocalFilter: true },
        { title: this.lang.activities.table.end_date, key: 'end_date', display: true, filter: true, preventLocalFilter: true },
        { title: this.lang.activities.table.actions, slot: 'actions', alignment: 'right', display: this.user.isAdmin, columnFilterable: false },
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchActivities: 'activities/index',
      deleteActivity: 'activities/delete'
    }),
    search(query) {
      this.fetchActivities({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ ...this.query, page: this.$route.query.page || 1 })
  }
}
</script>
